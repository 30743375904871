@import url('https://fonts.googleapis.com/css?family=Pavanam');

$primary: #20366c; /* MAIN COLOR */
$secondary: #a6b0c5; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;
$bg: #e7e2e2;
// html,body {
//   height: 100%;
// }
.pb-20 {
  padding-bottom: 20px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-60 {
  padding-bottom:60px;
}
body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  font-family: 'Pavanam', sans-serif;
}
.flash {
	display:none;
}

q,blockquote {
    quotes: "“" "”";
}
/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

/** NAV START **/
.navbarFixed {
 position: fixed;
 width: 100%;
 top: 0;
 z-index: 999;
}

nav.navbar {
	z-index: 1000;
	border: none;
	border-radius: $border-radius;

	.navbar-nav {

	    > li > a {
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: $blk;
      font-size: 1.3em;

			@media (max-width: 767px) {
				  margin-top: 0;
			    display: inline-block;
          padding-top: 2px;
          padding-bottom: 2px
			}

		    &:hover {
		    	background: $primary;
		    	color:$wht;
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $blk;
				  	outline: 0;
		    	}
		    }

			&:focus, &:active {
			  background: transparent;
			  color: $blk;
			  outline: 0;
			}
		}
	}
}

.navbar-right {
	margin-top: 0px;
}
.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/
@mixin btn {
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	display: inline-block;
	margin: 1em 0em;
  text-decoration: none;

	&:hover {
		background: $wht;
		color: $primary;
    border: none;
		box-shadow: 0px 0px 3px lighten($blk,10%);
    @media(max-width: 1024px){
      background: $primary;
    	color: $wht;
    }
	}
}
@mixin btn1 {
	border-radius: 100px;
  border: 1px solid $wht;
	background: none;
	color: $wht;
	padding: .7em 3em;
	font-size: 1em;
	font-weight: 300;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 2px;
	display: inline-block;
	margin: 1em 0em;
  text-decoration: none;
  transition: all 0.7s ease 0s;


	&:hover {
		background: $wht;
		color: $primary;
    font-weight: bold;
		box-shadow: 0px 0px 3px lighten($blk,10%);
    @media(max-width: 1024px){
      border: 1px solid $wht;
    	background: none;
    	color: $wht;
    }
	}
}

.btn-default {
	@include btn;
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: 1px solid #777;
		text-align: center;
	}

    input#username, input#password {
  		@media (max-width: 1024px) {
   			font-size: 16px;
  		}
 	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;
		font-size: 18px;

		&:hover {
			background: transparent;
			color: $primary;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn;
		display: block;
		width: 100%;
		font-size: 18px;
    	margin: 0 auto;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}

input#username {
	margin-bottom: 20px;
}
//sections

section{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 80px 0;
  @media(max-width:767px){
    padding: 40px 0;
  }
  h1 {
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 2em;
    font-weight: bold;

    @media(max-width:767px){
      font-size: 1.5em;
    }
    @media(max-width:500px){
      font-size: 1.3em;
    }
    @media(max-width:400px){
      font-size: 1em;
    }
  }
  p {
    font-size: 1.2em;
    line-height: 1.6em;
    @media(max-width:500px){
      font-size: 1em;
    }
    @media(max-width:400px){
      font-size: .8em;
    }
  }
}
.section-a {
  background-color: $primary;
  background-image: url('../img/bg1.jpg');
  background-attachment: fixed;
  padding:260px 0;
  color: $blk;
  text-align: center;
  p {
    font-size: 1.5em;
    text-transform: uppercase;
    @media(max-width:767px){
      font-size: 1.2em;
    }
    @media(max-width:600px){
      font-size: 1em;
    }
  }
  @media(max-width:1024px){
    background-attachment: scroll;
    padding: 200px 0;
  }
  @media(max-width:991px){
    padding: 120px 0;
  }
  @media(max-width:767px){
    padding: 70px 0;
  }

}
.section-b {
  background-color: $primary;
  background-position: top center;
  color: $wht;
  padding: 120px 0;
  @media(max-width:991px){
    padding:40px 0;
  }
  a {
    font-size: 1.5em;
    @include btn1;
  }

  img{
    margin-top:80px;

  }
}

.section-d {
  background-color: lighten($bg, 4%);
  text-align: center;
  .text-services {
    margin-bottom: 45px;
  }
  .col-md-4{
    @media(max-width: 767px){
      margin-bottom: 35px;
    }
    i {
      font-size: 5em;
      color: $primary;
      border: 1px solid $primary;
      border-radius: 100px;
      padding: 40px;
      margin-bottom: 40px;
    }
  }
  h3 {
    font-weight: bold;
    text-transform: uppercase;
    font-weight: 300;
  }
}
.section-e {
  background-color: $bg;
  background-image: url('../img/bg2.jpg');
  background-attachment: fixed;
  padding: 260px 0;
  color: $wht;
  text-shadow: 0px 0px 10px $blk;
  @media(max-width: 1024px){
    padding: 200px 0;
    background-attachment: scroll;
  }
  @media(max-width: 991px){
    padding: 180px 0;
  }
  @media(max-width: 767px){
    padding: 150px 0;
  }
  @media(max-width: 767px){
    padding: 100px 0;
  }
  h1 {
    font-size: 2em;
    font-weight: 300;
    line-height: 1.8em;
    @media(max-width: 767px){
      font-size: 1.2em;
    }
    @media(max-width: 600px){
      font-size: 1.1em;
      font-size: 400;
      line-height: 1.3em;
    }
    @media(max-width: 500px){
      font-size: 1em;
    }
  }
}
.section-f {
  background-color: $secondary;
  h3 {
    color: $blk;
    font-size: 1.2em;
    @media(max-width:767px){
      font-size: 1em;
    }
    @media(max-width:420px){
      text-align: center;
    }
    i {
      padding-right: 20px;
      @media(max-width:420px){
        width: 100%;
        padding-bottom: 25px;
        text-align: center;
        font-size: 2em;
      }
    }
    a {
      text-decoration: none;
      color: $blk;
    }
  }

 h1 {
   font-size: 2em;
   text-align: center;
   font-weight: 300;
   line-height: 1.4em;
   letter-spacing: 2px;
   color: $blk;
   @media(max-width:500px){
     font-size: 1.1em;
   }
 }
 h4 {
   font-size: 1.2em;
   text-transform: uppercase;
   color: $blk;
   line-height: 1.5em;
   letter-spacing: 1px;
   text-align: center;
   margin-top: 5%;
 }
 hr {
   background: $blk;
   color: $blk;
 }
}
// sections end
/** END LOGIN FORM **/
footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;
	font-size: 15px;


	a {
		color: $footerLinks;
		white-space: nowrap;
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}

		&:hover{
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $wht;
			outline: 0 !important;
 			text-decoration: none;
		}
	}

	p {
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}
}


input#username, input#password {
	width: 100%;

	@media (max-width: 1024px) {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}
a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


.logo {
	max-height: 100px;
	padding: 0.5em;
}


@media(max-width: 767px) {
	.logo {
		max-height:70px;
	}

    .navbar-header a {
        float: left;
    }
}
